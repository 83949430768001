import styled from '@emotion/styled';

export default styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  .brands-grid {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: 10px;
    height: 100%;
  }
  .brands-grid-item {
    ${({ theme }) => theme.fonts.openSans.bold};
    color: ${({ theme }) => theme.colors.bahamaBlue};
    background-color: ${({ theme }) => theme.colors.white};
    display: flex;
    gap: 15px;
    flex-direction: column;
    border-radius: 4px;
    align-items: center;
    padding: 15px 10px 10px;
    justify-content: space-between;
    font-size: 12px;
    line-height: 120%;
    min-height: 105px;
    border: solid 0px ${({ theme }) => theme.colors.bahamaBlue};
    transform: scale(1);
    transition: transform 150ms ease-in-out;
    ${({ theme }) => theme.media.hover} {
      &:hover {
        transform: scale(1.08);
      }
    }
    &:active {
      transform: scale(1.05);
    }
    picture {
      flex: 1;
      display: flex;
      align-items: center;
    }
  }
`;
