export const debouncePromise = (callback, delay, resolveAll = false) => {
    const storage = {
        timeout: null,
        promises: [],
    }

    return (...args) => {
        clearTimeout(storage.timeout);
        storage.timeout = setTimeout(() => {
            const result = callback(...args);
            if (resolveAll) {
                storage.promises.forEach(({ resolve }) => resolve(result));
            } else {
                const [last, ...rest] = storage.promises.reverse();
                last?.resolve(result);
                rest.forEach(({ reject }) => reject("cancelled"));
            }
            storage.promises = [];
        }, delay);
        return new Promise((resolve, reject) => storage.promises.push({ resolve, reject }));
    }


}


const debounce = (callback, delay) => {
    let timeout = null;
    return (...args) => {
        clearTimeout(timeout);
        timeout = setTimeout(callback, delay, ...args);
    }
}

export default debounce