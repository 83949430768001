import React, { useEffect, useMemo, useRef, useState } from 'react';
import StyledQuickSearch from './QuickSearch.style';
import TextField from './global/TextField';
import FieldSet from './global/FieldSet';
import Button from './global/Button';
import FancySelectField from './global/FancySelectField';
import Typography from './global/Typography';
import { debouncePromise } from '../app/helpers/debounce';

const adCountFormat = Intl.NumberFormat('nl-NL', {});

const QuickSearch = ({
  brandLabel,
  modelLabel,
  budgetLabel,
  brandPlaceholder,
  modelPlaceholder,
  budgetPlaceholder,
  keywordsLabel,
  keywordsPlaceholder,
  submitButtonText,
  getButtonHref,
  loadingText,
  brands: initialBrands,
  models: initialModels,
  budgets: initialBudgets,
  getBrandOptions,
  getModelOptions,
  getBudgetOptions,
  getAdCount,
  ...p
}) => {
  const [brand, setBrand] = useState(null);
  const [model, setModel] = useState(null);
  const [budget, setBudget] = useState(null);
  const [keywords, setKeywords] = useState('');
  const [adCount, setAdCount] = useState(null);

  const [brandProps, setBrandProps] = useState({
    options: initialBrands || [],
    isLoading: false,
    static: !!initialBrands?.length,
  });
  const [modelProps, setModelProps] = useState({
    options: initialModels || [],
    isLoading: false,
  });
  const [budgetProps, setBudgetProps] = useState({
    options: initialBudgets || [],
    isLoading: false,
    static: !!initialBudgets?.length,
  });

  const getters = useRef({});

  getters.current.brands = getBrandOptions;
  getters.current.models = getModelOptions;
  getters.current.budgets = getBudgetOptions;
  getters.current.adCount = useMemo(() => debouncePromise(getAdCount, 500), [
    getAdCount,
  ]);

  useEffect(async () => {
    try {
      const adCount = await getters.current.adCount({
        brand,
        model,
        budget,
        keywords,
      });
      setAdCount(adCount);
    } catch (e) {
      if (e === 'cancelled') return;
      console.error(e);
    }
  }, [
    JSON.stringify(brand),
    JSON.stringify(model),
    budget,
    keywords,
    getters.current,
  ]);

  useEffect(async () => {
    if (!getters.current.brands || brandProps.static) return;
    setBrandProps((p) => ({ ...p, isLoading: true }));
    const options = await getters.current.brands();
    setBrandProps((p) => ({ ...p, options, isLoading: false }));
  }, [brandProps.static, getters.current, getBrandOptions]);

  useEffect(async () => {
    if (!getters.current.models || !brand) {
      setModelProps((p) => ({ ...p, options: [], isLoading: false }));
      return;
    }
    setModel(null);
    setModelProps((p) => ({ ...p, isLoading: true }));
    const options = await getters.current.models(brand);
    setModelProps((p) => ({ ...p, options, isLoading: false }));
  }, [JSON.stringify(brand), getters.current, getModelOptions]);

  useEffect(async () => {
    if (!getters.current.budgets || budgetProps.static) return;
    setBudgetProps((p) => ({ ...p, isLoading: true }));
    const options = await getters.current.budgets();
    setBudgetProps((p) => ({ ...p, options, isLoading: false }));
  }, [budgetProps.static, getters.current]);

  return (
    <StyledQuickSearch {...p}>
      <FieldSet className="field-brand" label={brandLabel}>
        <FancySelectField
          isClearable
          value={brand}
          onChange={(value) => setBrand(value)}
          placeholder={brandPlaceholder}
          loadingMessage={loadingText}
          {...brandProps}
        />
      </FieldSet>
      <FieldSet className="field-model" label={modelLabel}>
        <FancySelectField
          isClearable
          value={model}
          onChange={(value) => setModel(value)}
          placeholder={modelPlaceholder}
          loadingMessage={loadingText}
          {...modelProps}
        />
      </FieldSet>
      <FieldSet className="field-budget" label={budgetLabel}>
        <FancySelectField
          isClearable
          value={budget}
          onChange={(value) => setBudget(value)}
          placeholder={budgetPlaceholder}
          loadingMessage={loadingText}
          {...budgetProps}
        />
      </FieldSet>
      <FieldSet className="field-keywords" label={keywordsLabel}>
        <TextField value={keywords} placeholder={keywordsPlaceholder} onChange={setKeywords} clearable />
      </FieldSet>
      <Button
        className="submit-button"
        variant="emerald"
        large
        href={getButtonHref({
          brand,
          model,
          budget,
          keywords,
        })}
      >
        <span className="text">{submitButtonText}</span>
        <Typography className="ad-count" variant="control">
          {!!adCount && `(${adCountFormat.format(adCount)})`}
        </Typography>
      </Button>
    </StyledQuickSearch>
  );
};
export default QuickSearch;
