import React from 'react';
import Picture from './global/Picture';
import StyledPopularBrands from './PopularBrands.style';

const PopularBrands = ({ brands, ...p }) => {
  return (
    <StyledPopularBrands {...p}>
      <div className="brands-grid">
        {brands.map(({ logo, link: { content: name, ...link } }, ix) => (
          <a key={ix} className="brands-grid-item" {...link}>
            <Picture
              alt={`${name} logo`}
              width={72}
              height={52}
              images={{
                1: logo.src,
              }}
            />
            <div className="brand-name">{name}</div>
          </a>
        ))}
      </div>
    </StyledPopularBrands>
  );
};
export default PopularBrands;
