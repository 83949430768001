import styled from '@emotion/styled';
import Container from '../../components/global/Container';

export default styled(Container)`
  --layout-extra-pl: 0px;
  --layout-extra-pr: 0px;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-areas: 'h1' 'qs' 'pt' 'pb' 'bt';
  gap: 15px;

  .title {
    grid-area: h1;
  }
  .quick-search {
    grid-area: qs;
  }
  .popular-brands-title {
    margin-top: 5px;
    grid-area: pt;
    align-self: flex-end;
  }
  .popular-brands {
    grid-area: pb;
  }
  .all-brands {
    grid-area: bt;
    justify-self: center;
    ${({ theme }) => theme.media.laptop} {
      justify-self: unset;
    }
  }
  .usps {
    display: none;

    ${({ theme }) => theme.media.desktopSmall} {
      display: flex;
      .usp {
        display: flex;
        align-items: center;
        font-size: 1rem;
        margin-right: 22px;

        svg {
          margin-right: 12px;
          background: radial-gradient(circle at center, rgba(255, 255, 255, 1) 0% 50%, rgba(255, 255, 255, 0) 50% 100%);
        }
      }
    }
  }
  ${({ theme }) => theme.media.laptop} {
    --pt: 30px;
    gap: 20px 30px;
    grid-template-columns: 1fr 385px;
    grid-template-rows: auto 1fr auto;
    grid-template-areas: 'h1 pt' 'qs pb' 'qs bt';
    margin-bottom: -30px;
  }
  ${({ theme }) => theme.media.desktopSmall} {
    grid-template-areas: 'h1 pt' 'qs pb' '. bt';
  }
`;
